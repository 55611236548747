import React from "react"
import { Layout } from "../components/layout/Layout"
import { Box, Button, Heading } from "@chakra-ui/core"
import { Heading2 } from "../components/headings/Heading2"
import { Link } from "gatsby"

const Page = () => {
  return (
    <Layout>
      <Box
        display="flex"
        justifyContent="center"
        flexDir="column"
        m="1rem auto"
      >
        <Heading as="h3">Voi ei!</Heading>
        <Heading2>Sivua ei löytynyt</Heading2>
        <Box m="1rem auto">
          <Link to="/">
            <Button cursor="pointer">Palaa etusivulle!</Button>
          </Link>
        </Box>
      </Box>
    </Layout>
  )
}

export default Page
